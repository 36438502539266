.StripeElement {
  height: 44px;
  padding: 12px;
  color: #181b26;
  outline-color: unset !important;
  background-color: white;
  border: 1px solid #b33939;
}

.StripeElement--focus.StripeElement--invalid {
  box-shadow: 0px 0px 0px 2px rgba(255, 90, 37, 0.25);
}

.StripeElement--focus {
  background-color: white !important;
  box-shadow: 0px 0px 0px 2px rgba(179, 57, 57, 0.25);
  border-color: #b33939 !important;
}

.StripeElement--empty {
  border: 1px solid #f4f6f8;
  background-color: #f4f6f8;
}

.StripeElement--complete {
  background-color: white;
  border-color: #b33939;
}

.StripeElement--invalid {
  border-color: #d62a2a;
  box-shadow: 0 0 0 0.2rem rgba(214, 42, 42, 0.5);
}

.StripeElement--disabled {
  opacity: 0.3;
}

.StripeElement::placeholder {
  color: #cacbcd;
}
