.multi-carousel-item-mainpage {
  padding: 0 12px;
  display: flex;
  width: 250px !important;
}

.multi-carousel-item-mainpage:first-child {
  padding-left: 0;
}
.multi-carousel-item-mainpage:last-child {
  padding-right: 0;
}
