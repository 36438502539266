body,
html,
#etalon-diagnostics {
  height: 100%;
  font-size: 16px;
  font-family: Roboto;
}

.h-100vh {
  height: 100vh;
}

.custom-control {
  z-index: auto;
}

.rdtPicker {
  width: fit-content !important;
}
