/* TODO: Move to styled-components */

.paginationContainer {
  list-style-type: none;
  text-align: center;
  display: inline;
  padding: 0;
  margin: 0;
}

.paginationContainer > li {
  display: inline;
}

.paginationElement {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-right: 8px;
  cursor: pointer;
}

@media (min-width: 576px) {
  .paginationElement {
    font-size: 16px;
  }
}

.link,
.link:hover {
  padding: 6px 8px;
  line-height: 24px;
  outline: none;
  text-decoration: none;
  color: inherit;
}

.page,
.break {
  user-select: none;
  color: #c1c2c3;
}

.page:hover,
.break:hover {
  color: #181b26;
}

.activePage {
  color: #181b26;
}

.paginationButton {
  fill: #dedfe1;
  display: inline;
  cursor: pointer;
}

.paginationButton:hover {
  fill: #b33939;
}

.paginationButton > a {
  outline: none;
}

.paginationButton > a > svg {
  width: 18px;
  height: 18px;
}

.previous {
  margin-right: 8px;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}
