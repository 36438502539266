.breed-filter:hover {
  background-color: #b339393d !important;
}

.close-up-bg {
  opacity: 0.5;
}

.close-up {
  display: none;
}
