.dropdownItem {
  padding: 0 !important;
  color: #181b26 !important;
}

.dropdownItem:hover {
  background-color: #f4f6f8;
}

.dropdownItem:active {
  background-color: #f4f6f8;
}
