.str-root table {
  border-collapse: collapse;
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.04);
}

.str-root caption {
  color: black;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
}

.str-root thead th {
  background-color: #8c0a0a;
  color: white;
  font-weight: 500;
}

.str-root th,
td {
  padding: 0.3rem 1rem;
}

.str-root tr:nth-of-type(2n + 1) {
  background: hsla(0, 0%, 0%, 0.04) !important;
}

/* top-left border-radius */
.str-root table thead tr:first-child th:first-child {
  border-top-left-radius: 4px;
}

/* top-right border-radius */
.str-root table tr:first-child th:last-child {
  border-top-right-radius: 4px;
}

/* bottom-left border-radius */
.str-root table tbody tr:last-child th:first-child,
.str-root table tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

/* bottom-right border-radius */
.str-root table tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.parentage-results-container {
  display: inline-flex;
  align-items: baseline;
}
.parentage-results-container div:nth-child(2) {
  margin-left: auto;
  margin-right: 20px;
}

.str-table {
  width: 326px;
  margin-top: 7px;
}
.str-table th {
  text-align: center;
  background: hsla(0, 0%, 0%, 0.15);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.str-table td {
  font-size: 15px;
  padding: 0.3rem 0.8rem;
}

.result-description {
  margin-top: 0.8rem;
  max-width: 450px;
  align-self: start;
}

.result-description h4 {
  padding: 6px 0px;
}

.mismatch {
  /* border: 2px hsla(7, 100%, 50%, 0.692); */
  outline: hsl(7, 85%, 50%) solid 2px;
}

.gendermismatch {
  /* background:hsla(56, 100%, 56%, 0.774); */
  outline: 2px solid hsla(46, 100%, 56%, 0.774);
}

.clampLh2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0.3rem 1rem;
}
@media (max-width: 650px) {
  .parentage-results-container {
    display: block;
  }
  .parentage-results-container div:nth-child(2) {
    margin-right: 0px;
  }

  .str-root table {
    margin-left: auto;
    margin-right: auto;
  }
}
